<template>
  <div class="patient-form-view">
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
    <app-header icon="users" :title="pageTitle" goes-back></app-header>

    <section v-if="!isLoading">
      <div class="card">
        <div class="card-content">
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-3">
                <app-input
                  label="CPF"
                  v-model="patient.cpf"
                  :errors="errors.cpf"
                  :mask="MASKS.cpf"
                  :disabled="!isEditable"
                  is-required
                ></app-input>
              </div>
              <div class="col-3">
                <app-input
                  label="Nome"
                  v-model="patient.name"
                  :errors="errors.name"
                  :disabled="!isEditable"
                  is-required
                >
                </app-input>
              </div>
              <div class="col-3">
                <app-input
                  label="E-mail"
                  v-model="patient.email"
                  type="email"
                  autocomplete="email"
                  :errors="errors.email"
                  :disabled="!isEditable"
                  is-required
                ></app-input>
              </div>
              <div class="col-3">
                <app-input
                  label="Telefone"
                  v-model="patient.phone"
                  type="tel"
                  v-mask="phoneMask"
                  :errors="errors.phone"
                  :disabled="!isEditable"
                  is-required
                ></app-input>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-3">
                <b-field class="required" label="Data de nascimento">
                  <b-datepicker
                    v-model="patient.birthday"
                    locale="pt-BR"
                    placeholder="Selecionar data..."
                    icon="calendar"
                    :years-range="[-100, 0]"
                    :disabled="!isEditable"
                    trap-focus
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-3">
                <app-input
                  label="CEP"
                  @input="getCepInfo"
                  v-model="patient.cep"
                  type="text"
                  :mask="MASKS.cep"
                  :errors="errors.cep"
                  :disabled="!isEditable"
                  is-required
                ></app-input>
              </div>
              <div class="col-6">
                <app-input
                  label="Rua"
                  v-model="patient.street"
                  type="text"
                  :errors="errors.street"
                  :disabled="!isEditable"
                  is-required
                ></app-input>
              </div>
              <div class="col-3">
                <app-input
                  label="Número"
                  v-model="patient.number"
                  type="tel"
                  :errors="errors.number"
                  :disabled="!isEditable"
                  is-required
                ></app-input>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-4">
                <app-input
                  label="Complemento"
                  v-model="patient.complement"
                  type="text"
                  :errors="errors.complement"
                  :disabled="!isEditable"
                ></app-input>
              </div>
              <div class="col-4">
                <app-input
                  label="Bairro"
                  v-model="patient.neighborhood"
                  type="tel"
                  :errors="errors.neighborhood"
                  :disabled="!isEditable"
                  is-required
                ></app-input>
              </div>
              <div class="col-4">
                <app-autocomplete
                  label="Cidade"
                  ref="citySelector"
                  v-model="patient.city_id"
                  :value="patient.city_id"
                  :getter="citiesGetter"
                  :setter="citySetter"
                  :errors="errors.city_id"
                  :disabled="!isEditable"
                  field="name"
                  is-required
                ></app-autocomplete>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 has-text-right">
                <b-button
                  @click="onSubmit"
                  :loading="isSaving"
                  :disabled="isLoading || isSaving"
                  type="is-primary"
                >
                  Salvar
                </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div v-if="patientId" class="card mt-4">
        <div class="card-header">
          <div class="card-header-title">Prontuários e Prescrições</div>
        </div>
        <div class="card-content">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <div class="card-header-icon">
                    <b-icon icon="clipboard"></b-icon>
                  </div>
                  <div class="card-header-title">Prontuários</div>
                  <div class="card-header-buttons">
                    <b-tooltip label="Novo Prontuário">
                      <b-button
                        @click="openRecord()"
                        type="is-dark"
                        icon-left="plus"
                        size="is-small"
                      >
                        Adicionar
                      </b-button>
                    </b-tooltip>
                  </div>
                </div>
                <div class="card-content custom-scroll">
                  <medical-records-table
                    v-if="!isLoading"
                    ref="recordsTable"
                    @open="openRecord($event)"
                    @delete="onDeleteClick($event)"
                    :patientId="patientId"
                  ></medical-records-table>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <div class="card-header-icon">
                    <b-icon icon="clipboard"></b-icon>
                  </div>
                  <div class="card-header-title">Prescrições</div>
                  <div class="card-header-buttons">
                    <b-tooltip
                      size="is-small"
                      position="is-left"
                      label="Novo Receituário"
                      style="margin-right: 5px"
                    >
                      <b-button
                        @click="openPrescription()"
                        type="is-dark"
                        icon-left="plus"
                        size="is-small"
                      >
                        Adicionar
                      </b-button>
                    </b-tooltip>
                    <b-tooltip
                      size="is-small"
                      position="is-left"
                      label="Novo Receituário via Memed"
                      v-if="memedToken"
                    >
                      <prescription-memed
                        :patient="patient"
                        @reload="reloadPrescriptionsTable()"
                        @view="canViewPrescription($event)"
                      ></prescription-memed>
                    </b-tooltip>
                  </div>
                </div>
                <div class="card-content custom-scroll">
                  <prescriptions-table
                    v-if="!isLoading"
                    ref="prescriptionsTable"
                    @open="openPrescription($event)"
                    :viewOption="!viewPrescription && !!memedToken"
                    :patientId="patientId"
                  ></prescriptions-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CitiesService from '@/services/cities.service';
import CepService from '@/services/cep.service';
import PatientsService from '@/services/patients.service';
import AppAutocomplete from '@/components/inputs/Autocomplete.vue';

import Prescription from '@/modals/Prescription.vue';
import MedicalRecord from '@/modals/MedicalRecord.vue';

import PrescriptionsTable from '@/components/PrescriptionsTable.vue';
import MedicalRecordsTable from '@/components/MedicalRecordsTable.vue';

import MedicalRecordModelService from '@/services/medical-records.service';

import MASKS from '@/constants/masks.constant';
import * as moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  components: {
    AppAutocomplete,
    MedicalRecordsTable,
    PrescriptionsTable,
  },
  data: () => ({
    MASKS: MASKS,
    patientId: null,
    isLoading: false,
    isSaving: false,
    patient: {
      birthday: null,
    },
    errors: {},
    viewPrescription: false,
  }),
  computed: {
    pageTitle() {
      if (!this.isEditable) return 'Visualizar Paciente';
      return `${this.patientId ? 'Editar' : 'Cadastrar'} Paciente`;
    },
    isEditable() {
      if (!this.patientId) return true;
      return this.patient.is_editable;
    },
    phoneMask() {
      if (this.patient.phone && this.patient.phone.length > 14) {
        return '(##) #####-####';
      } else {
        return '(##) ####-####';
      }
    },
    ...mapGetters(['memedToken']),
  },
  methods: {
    citiesGetter: CitiesService.search,
    citySetter: CitiesService.getId,
    onSubmit() {
      this.isSaving = true;
      this.errors = {};

      const { cpf, cep, birthday } = this.patient;
      const data = {
        ...this.patient,
        cpf: cpf.replace(/\D/g, ''),
        cep: (cep || '').replace(/\D/g, ''),
        birthday: moment(birthday).format('YYYY-MM-DD'),
      };

      const promise = this.patientId
        ? PatientsService.update(this.patientId, data)
        : PatientsService.store(data);

      promise
        .then(() => {
          this.isSaving = false;
          this.$buefy.snackbar.open('Paciente salvo com sucesso.');
          this.$router.go(-1);
        })
        .catch(({ response }) => {
          const { status, data } = response;

          if (status === 422) this.errors = data.errors;
          else
            this.$buefy.snackbar.open(
              data.message || 'Um erro inesperado aconteceu'
            );

          this.isSaving = false;
        });
    },
    getCepInfo(cep) {
      if (!cep) return;

      cep = cep.replace(/\D/g, '');
      if (cep.length < 8) return;

      CepService.getCepInfo(cep).then(({ data }) => {
        this.patient = {
          ...this.patient,
          street: data.logradouro,
          neighborhood: data.bairro,
        };

        this.$refs.citySelector.loadResourceById(data.city_id);
      });
    },
    loadPatient(id) {
      this.isLoading = true;

      PatientsService.getId(id)
        .then(({ data }) => {
          this.patient = {
            ...data,
            cpf: data.cpf.replace(
              /^(\d{3})(\d{3})(\d{3})(\d{2})/,
              '$1.$2.$3-$4'
            ),
            phone: (data.phone || '').replace(
              /^(\d{2})(\d{4})(\d{4})/,
              '($1) $2-$3'
            ),
            cep: (data.cep || '').replace(/^(\d{2})(\d{3})(\d{3})/, '$1.$2-$3'),
            birthday: new Date(data.birthday),
            email: data.user.email,
          };
        })
        .catch(() => this.$buefy.snackbar.open('Erro ao carregar paciente.'))
        .finally(() => (this.isLoading = false));
    },
    openRecord(record = null) {
      this.$buefy.modal.open({
        parent: this,
        component: MedicalRecord,
        hasModalCard: true,
        width: 3000,
        trapFocus: true,
        props: {
          record,
          patientId: this.patientId,
        },
        events: {
          close: (data) => data && this.$refs.recordsTable.loadPatientRecords(),
        },
      });
    },
    openPrescription(prescription = null) {
      if (prescription && prescription.by_memed) {
        window.MdHub.command.send(
          'plataforma.prescricao',
          'viewPrescription',
          prescription.memed_prescription_id
        );
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: Prescription,
          width: 3000,
          hasModalCard: true,
          trapFocus: true,
          props: {
            prescription,
            patientId: this.patientId,
          },
          events: {
            close: (data) =>
              data && this.$refs.prescriptionsTable.loadPrescriptions(),
          },
        });
      }
    },
    onDeleteClick(recordId) {
      this.$buefy.dialog.confirm({
        message: 'Deseja realmente deletar esse prontuário?',
        onConfirm: () => this.deleteRecord(recordId),
      });
    },
    deleteRecord(id) {
      MedicalRecordModelService.deleteRecord(id).then(() => {
        this.$buefy.snackbar.open('Prontuário deletado com sucesso.');
        this.$refs.recordsTable.loadPatientRecords();
      });
    },
    reloadPrescriptionsTable() {
      this.$refs.prescriptionsTable.loadPrescriptions();
    },
    canViewPrescription(value) {
      this.viewPrescription = value;
    },
  },
  beforeMount() {
    this.patientId = Number(this.$route.params.id);
    this.patientId && this.loadPatient(this.patientId);
  },
};
</script>
